import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  MSAL_GUARD_CONFIG,
  MsalGuardConfiguration,
  MsalService,
} from '@azure/msal-angular';
import {
  AuthenticationResult,
  InteractionType,
  PopupRequest,
  RedirectRequest,
} from '@azure/msal-browser';
import { ProfileService } from '../profile.service';
import { AppConstants } from '../../appconstants';
import { BehaviorSubject, Observable } from 'rxjs';
import { SignUpRoles } from '@portal/configs';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { AppMonitorService } from '../app-monitor.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  loggedIn = new BehaviorSubject<boolean>(false);
  currentUserStatus = this.loggedIn.asObservable();

  apiUrl = environment.apiUrl;
  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private _msalService: MsalService,
    private _httpClient: HttpClient,
    private _profileService: ProfileService,
    private _router: Router,
    private _appMonitorService: AppMonitorService
  ) {}

  // isAuthenticated(): boolean {
  //   // Replace with your actual authentication logic
  //   return !!localStorage.getItem('Access-Token-Azure-B2C');
  // }

  /**Initiates the login process for the application.
   * @param userFlowRequest - Optional parameter to specify custom user flow request.
   * If not provided, the default configuration from MSAL_GUARD_CONFIG will be used.
   * @remarks
   * This function handles both popup and redirect interactions based on the interactionType
   * specified in MSAL_GUARD_CONFIG.It merges the provided userFlowRequest with the//+
   * default configuration if available.
   * @returns {void}
   **/
  login(userFlowRequest?: RedirectRequest | PopupRequest): void {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      if (this.msalGuardConfig.authRequest) {
        this._msalService
          .loginPopup({
            ...this.msalGuardConfig.authRequest,
            ...userFlowRequest,
          } as PopupRequest)
          .subscribe((response: AuthenticationResult) => {
            this._msalService.instance.setActiveAccount(response.account);
          });
      } else {
        this._msalService
          .loginPopup(userFlowRequest)
          .subscribe((response: AuthenticationResult) => {
            this._msalService.instance.setActiveAccount(response.account);
          });
      }
    } else {
      if (this.msalGuardConfig.authRequest) {
        this._msalService.loginRedirect({
          ...this.msalGuardConfig.authRequest,
          ...userFlowRequest,
        } as RedirectRequest);
      } else {
        this._msalService.loginRedirect(userFlowRequest);
      }
    }
  }

  isLoggedIn(): boolean {
    return this.loggedIn.getValue();
  }

  checkUserStatus(username: string): Observable<string> {
    return this._httpClient.get<string>(
      `${this.apiUrl}/api/users/status/${username}`
    );
  }

  getUerProfile(email: string): Observable<string> {
    return this._httpClient.get<string>(
      `${this.apiUrl}/user/profile/email/${email}`
    );
  }

  getMe(): Observable<string> {
    return this._httpClient.get<string>(`${this.apiUrl}/user/me`);
  }

  getUserByEmail(email: any): void {
    this._profileService.getUserProfileByEmail(email).subscribe(
      (response: any) => {
        if (response && response.success && response.data) {
          const userData = response.data;
          const basicUserDetails = {
            userId: userData.userProfile.id,
            firstName: userData.firstName,
            lastName: userData.lastName,
            role: userData.role,
            email: email,
          };
          // Store the basic user details in local storage
          localStorage.setItem(
            AppConstants.USER,
            JSON.stringify(basicUserDetails)
          );

          if (userData.role === SignUpRoles.Employer) {
            return this._router.navigate([AppConstants.JOBSEEKER_PROFILE_URL]);
          } else if (userData.role === SignUpRoles.JobSeeker) {
            return this._router.navigate([AppConstants.JOBSEEKER_PROFILE_URL]);
          }
          return this._router.navigate(['/']);
        } else {
          console.warn('No user details found for:', email);
        }
        return response;
      },
      (error: any) => {
        console.error('Error fetching user details:', error);
      }
    );
  }

  logout(): void {
    this._appMonitorService.clearUserContext();
    localStorage.removeItem('Access-Token-Azure-B2C');
    localStorage.removeItem(AppConstants.USER);
    localStorage.setItem(AppConstants.LOGINSTATUS, AppConstants.FALSE);
    this.loggedIn.next(false);
    this._msalService.logout();
  }

  public get isAuthenticated(): boolean {
    const activeAccount = this._msalService.instance.getActiveAccount();
    return activeAccount !== null;
  }
}
